import React from "react";
import Topbar from '../core/TopNav';
import Footer from "../core/Footer";
//import ChatBox from "./chat/ChatBox";

const Live = () => {
  
    return (
      <div>
        <div>
      <Topbar />
      </div>
      <div>
         
       <div>
       <div style={{width:'100%', marginTop:'25px', padding:'55px'}} className="live-video">
        <iframe id="29c3c660-13cb-674c-9edc-6cc5ce8e09b3-live-dbf1ecac-b51e-f817-e9df-e18ff90ad46f" title="Live Video on The Station TV" src="https://iframe.dacast.com/live/29c3c660-13cb-674c-9edc-6cc5ce8e09b3/dbf1ecac-b51e-f817-e9df-e18ff90ad46f" width="100%" height="100%" frameborder="0" allow="autoplay;encrypted-media" className="videoBox" allowFullscreen webkitAllowFullscreen mozAllowFullscreen oAllowFullscreen msAllowFullscreen></iframe>
        </div>
        {/* <div><ChatBox /></div> */}
        </div>


      </div>
      <Footer />
      
      </div>
    );


}

export default Live
